<template>
    <div class="edu-frame" style="background-color: #cde7f7;">
        <edu-top-header></edu-top-header>
        <div class="edu-group" style="margin: 20px auto;">
            <div class="step-tips">您好，请按照流程完成注册！</div>
            <div class="step-tips-detail">请填写手机号及密码进行注册</div>
            <div class="class-form">
                <van-form @submit="register">
                    <van-field required v-model="formData.idCardNo" label="身份证号码" placeholder="请输入身份证号码" clearable :rules="[{ required: true, message: '请输入身份证号码' }]" @input="changeIDCardNo"/>
                    <van-field required v-model="formData.fullName" label="姓名" placeholder="请输入姓名" clearable :rules="[{ required: true, message: '请填写姓名' }]"/>
                    <van-field required readonly clickable name="datetimePicker" :value="formData.birthday_GreStr" label="出生日期" placeholder="点击选择时间" @click="birthdayPicker = true"/>
                    <van-field required v-model="formData.mobileTelNo" label="手机号码" placeholder="请输入手机号码" clearable :rules="[{ required: true, message: '请填写手机号码' }]"/>
                    <van-field required v-model="formData.password" type="password" label="密码" placeholder="请输入密码" clearable :rules="[{ required: true, message: '请填写密码' }]"/>
                    <van-field required v-model="formData.repassword" type="password" label="确认密码" placeholder="确认密码" clearable :rules="[{ required: true, message: '请填写确认密码' }]"/>
                    <van-field v-model="formData.verifyCode" center clearable label="验证码" placeholder="请输入验证码">
                        <template #button>
                            <van-button :disabled="time > 0" size="small" block type="info" native-type="button" @click="sendSms()">{{verifybtntxt}}</van-button>
                        </template>
                    </van-field>
                    <div class="button-group">
                        <van-button size="small" block type="info" native-type="button" @click="$router.go(-1);">上一步</van-button>
                        <van-button :loading="submitting" size="small" block type="info" native-type="submit">注册</van-button>
                    </div>
                </van-form>
            </div>
        </div>


      <!-- 选择生日 -->
      <van-popup v-model="birthdayPicker" position="bottom">
        <van-datetime-picker v-model="formData.birthday_Gre" value type="date" title="选择年月日"  :min-date="minDate" :max-date="maxDate" @confirm="selectBirthday()" @cancel="birthdayPicker=false"/>
      </van-popup>
      <!-- 选择生日 -->
    </div>
</template>
<script>
    import EduTopHeader from "@/components/EduHeader";
    import {Form, Field, Button,Popup, DatetimePicker} from 'vant';
    import CustomerApi from "@/api/CustomerApi";
    import XEUtils from "xe-utils";

    export default {
        components: {
            EduTopHeader,
            VanForm: Form,
            VanField: Field,
            VanButton: Button,
            VanPopup: Popup,
            VanDatetimePicker: DatetimePicker
        },
        data() {
            return {
                time: 0,
                submitting: false,
                timeInterval: null,
                verifybtntxt: '获取验证码',
                formData: { },
                verify: { },
                birthdayPicker: false,
                minDate: new Date(1900, 0, 1),
                maxDate: new Date(),
            }
        },
        name: 'register',
        methods: {
          selectBirthday() {
            // 选择出生日期
            // this.formData.birthday_Gre = e
            this.$set(this.formData, 'birthday_GreStr', this.formData.birthday_Gre == null ? null : XEUtils.toDateString(this.formData.birthday_Gre, 'yyyy-MM-dd'))
            // this.$set(this.formData, 'birthday_Gre', XEUtils.toDateString(this.formData.birthday_Gre, 'yyyy-MM-dd HH:mm:ss'))
            this.birthdayPicker = false
          },
          register() {
              // 加载中就跳过
              if (this.submitting == true) {
                return
              }
              // console.log(this.formData)
              if (this.formData.verifyCode != this.verify.verifyCode) {
                this.$dialog.alert({message: "验证码错误。"})
                return
              }
              if (this.formData.mobileTelNo != this.verify.mobile) {
                this.$dialog.alert({message: "手机号码不是获取验证码的手机号码。"})
                return
              }
              if (this.formData.idCardNo == null || this.formData.idCardNo == '') {
                this.$dialog.alert({message: "请输入身份证号码。"})
                return
              }
              if (this.formData.birthday_Gre == null || this.formData.birthday_Gre == '') {
                this.$dialog.alert({message: "请输入出生日期。"})
                return
              }
              if (this.formData.fullName == null || this.formData.fullName == '') {
                this.$dialog.alert({message: "请输入姓名。"})
                return
              }
              if (this.formData.password == null || this.formData.password == '') {
                this.$dialog.alert({message: "请输入密码。"})
                return
              }
              if (this.formData.password != this.formData.repassword) {
                this.$dialog.alert({message: "两次密码输入不一致。"})
                return
              }
            this.$set(this.formData, 'birthday_Gre', XEUtils.toDateString(this.formData.birthday_Gre, 'yyyy-MM-dd HH:mm:ss'))
              this.submitting = true
              CustomerApi.register(this.formData).then(response => {
                this.submitting = false
                if (response.code == 100) {
                  this.$router.push({ name: 'index' })
                }
              }).catch(()=>{ this.submitting = false })
              // this.$router.push({ name: 'main' })
          },
          sendSms() {
            if (this.formData.mobileTelNo == null || this.formData.mobileTelNo == '') {
              this.$dialog.alert({message: "请输入手机号码"})
              return
            }

            var self = this
            self.time = 60
            self.timeInterval = setInterval(()=> {
              self.verifybtntxt = self.time + '秒后可重发'
              self.time--
              // console.log(self.time)
              if (self.time <= 0) {
                self.verifybtntxt = '获取验证码'
                self.time = 0
                clearInterval(self.timeInterval)
              }
            }, 1000)

            this.submitting = true
            CustomerApi.getPhoneCode({ mobile: this.formData.mobileTelNo}).then(response => {
              this.submitting = false
              this.verify = response.res
            }).then(() =>{ this.submitting = false })
          },
          changeIDCardNo() {
            // console.log('changeIDCardNo')
            var birthday = this.getBirthDayFrom(this.formData.idCardNo)
            // console.log(birthday)
            this.$set(this.formData, 'birthday_GreStr', birthday)
            this.$set(this.formData, 'birthday_Gre', XEUtils.toDateString(birthday, 'yyyy-MM-dd HH:mm:ss'))
          },
          getBirthDayFrom(idCard) {
            var birthday = "";
            if(idCard != null && idCard != ""){
              if(idCard.length == 15){
                birthday = "19"+idCard.substr(6,6);
              } else if(idCard.length == 18){
                birthday = idCard.substr(6,8);
              }

              birthday = birthday.replace(/(.{4})(.{2})/,"$1-$2-");
            }

            return birthday;
          }
        },
        mounted() {
          if (this.timeInterval != null) clearInterval(this.timeInterval)
        },
        destroyed() {
          if (this.timeInterval != null) clearInterval(this.timeInterval)
        }
    }
</script>
<style scoped>
    .step-tips-detail {
        font-size: 14px;
        margin-left: 15px;
        margin-top: 10px;
        margin-bottom: 15px;

    }
    .form-tips{
        margin-top: 5px;
        margin-bottom: 5px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
    }

    .button-group {
        margin: 16px;
        display: flex;
    }
    .van-button {
        margin: 0 5px;
    }
</style>
